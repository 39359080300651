<template>
    <div>
        <div v-if="false" class="py-0 my-0" @click="toggleAccordion" style="cursor: pointer">
            <CustomIcon v-if="!isDisplayFilterOpen" :size="24" color="green" glyph="mdi-chevron-down" />
            <CustomIcon v-if="isDisplayFilterOpen" :size="24" color="green" glyph="mdi-chevron-up" />
            Display Options
        </div>
        <div class="pl-3 pb-2" :class="['accordion-content', { open: isDisplayFilterOpen || isLargeViewport}]" >
            <v-switch v-if="isUnrankedToggle" v-model="showUnRanked" label="Show Unranked Items" @change="toggleUnRanked()" color="success" hide-details ></v-switch>
            <div v-if="!publicMode">
                <div class="mt-1">
                    <b>{{ list.public_participation_count }} </b> public participants
                </div>
                <div class="mt-1" v-if="isViewingSelf()">
                    <b>{{ friendList.length }} </b> friend participants
                </div>
                <div class="mt-1">
                    <a :href="domain + '/sys/tally/' +  listName"> view tally results </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '/src/utils/api';
import auth from "/src/utils/auth";

export default {
    components: {
        CustomIcon: () => import("/src/components/CustomIcon.vue"),
    },
    data() {
        return {
            domain: window.location.origin,
            friendList: [],
            isDisplayFilterOpen: false,
            showUnRanked: false,
            isUnrankedToggle: false,
            isViewingSelf: auth.isViewingSelf,
        };
    },
    computed: {
        isLargeViewport() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
    },
    watch: {
        // Watching for changes in the 'watchedProperty' data property
        watchedProperty(newValue, oldValue) {
            console.log("Value changed from", oldValue, "to", newValue);
        },
    },
    mounted() {
        if(this.isViewingSelf()) {
            api.getCall('get-friends').then(userList => {
                this.friendList = userList;
            });
        }
    },
    methods: {
        toggleAccordion() {
            this.isDisplayFilterOpen = !this.isDisplayFilterOpen;
        },
        showUnrankToggle() {
            this.isUnrankedToggle = true;
        }
    },
    props: ['list','listName','publicMode','toggleUnRanked'],
};
</script>
<style scoped>
.accordion-content {
    max-height: auto;
    overflow: hidden;
    transition: max-height 0.8s ease-out;
}

@media (min-width: 768px) {
    .accordion-content.open {
        max-height: auto;
    }
}
.open {
    max-height: 500px;
}
</style>